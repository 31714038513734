













































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { sort } from '@/utils/sort';
import { focusOnNewItemNameInput } from '@/utils/focus_on_input';
import { nameFilter } from '@/utils/name_filter';
import EditableListItem from '@/components/base/EditableListItem.vue';
import {
  AdminAction, AdminActionType, AdminState, UserGetter, UserGetterType
} from '@/store/modules/admin/types';
import { Models } from '@mtap-smartcity/api';

const admin = namespace('admin');

@Component({
  components: {
    EditableListItem,
  },
})
/**
 * @group Users Card
 * Users tab left panel - Groups list
 */
export default class UsersCardLeftPanelGroups extends Vue {
  modifiedGroup: Models.Auth.Groups.GroupsModel | null = null;

  key = 1;

  pending = false;

  dialog = false;

  deleteData: string | null = null;

  itemName = '';

  showNameTooltip: boolean = false;

  @admin.State
  groups!: AdminState['groups'];

  @admin.State
  selectedGroup!: AdminState['selectedGroup'];

  @admin.Action(AdminAction.SetSelectedGroup)
  setSelectedGroup!: AdminActionType['SET_SELECTED_GROUP']

  @admin.Action(AdminAction.AddGroupPlaceholder)
  addGroupPlaceholder!: AdminActionType['ADD_GROUP_PLACEHOLDER']

  @admin.Action(AdminAction.RemoveGroupPlaceholder)
  removeGroupPlaceholder!: AdminActionType['REMOVE_GROUP_PLACEHOLDER']

  @admin.Action(AdminAction.UpdateGroup)
  updateGroup!: AdminActionType['UPDATE_GROUP']

  @admin.Action(AdminAction.DeleteGroup)
  deleteGroup!: AdminActionType['DELETE_GROUP']

  @admin.Action(AdminAction.CreateGroup)
  createGroup!: AdminActionType['CREATE_GROUP']

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  // sort and filter groups using search field
  filteredGroups(searchPhrase: string) {
    let sortedGroups = [...this.groups].sort((a, b) => sort(a.name, b?.name));
    sortedGroups = sortedGroups.filter((item) => item.name !== 'HISTORICALPARAMETERSMODE');
    return sortedGroups.filter((g) => nameFilter(g.name, searchPhrase));
  }

  // check if the group from groups list is being edited
  isModifiedGroup(groupId: string) {
    return !!this.modifiedGroup && this.modifiedGroup.id === groupId;
  }

  // check if the group from groups list is selected (clicked)
  isSelectedGroup(groupId: string) {
    return !!this.selectedGroup && this.selectedGroup.id === groupId;
  }

  // check if the group object is a placeholder group object
  // (an object added to the list of group in the store, which serves as an input element for creating a new group)
  // in this case it doesn't have an id
  isPlaceholderGroup(group: Models.Auth.Groups.GroupsModel) {
    return group.id === null;
  }

  eventHandlers(group: Models.Auth.Groups.GroupsModel) {
    return {
      select: () => this.selectGroup(group),
      edit: () => this.editGroup(group),
      modalAction: () => {
        this.deleteData = group.id!;
        this.dialog = true;
      },
      discard: this.discard,
      save: this.save,
    };
  }

  closeModal() {
    this.dialog = false;
    this.deleteData = null;
  }

  deleteModal() {
    this.deleteGroup(this.deleteData!);
    this.deleteData = null;
    this.dialog = false;
  }

  selectGroup(group: Models.Auth.Groups.GroupsModel) {
    // disallow clicking on a group (selecting)
    // when some action is pending or if some group is currently being edited
    if (this.pending
      || this.modifiedGroup) return;
    this.setSelectedGroup(group);
  }

  editGroup(group: Models.Auth.Groups.GroupsModel) {
    // copy the group so that the original object is not modified
    this.modifiedGroup = { ...group };
    this.itemName = this.modifiedGroup.name;
    focusOnNewItemNameInput();
  }

  save() {
    if (!this.modifiedGroup!.name || this.groupNamesArray.includes(this.modifiedGroup!.name)) {
      focusOnNewItemNameInput();
      this.showNameTooltip = true;
      return;
    }
    // if modifiedGRoup is a placeholder object for a new group, create a new group
    // (it doesn't have an id in this case)
    if (this.isPlaceholderGroup(this.modifiedGroup!)) {
      this.showNameTooltip = false;
      this.createGroup(this.modifiedGroup!)
        .catch(() => {
          this.removeGroupPlaceholder();
        })
        .finally(() => {
          this.modifiedGroup = null;
        });
    } else {
      // else update the group that's been edited
      this.updateGroup(this.modifiedGroup!)
        .catch(() => {
          // force component rerender if updating failed
          // so that the group is displayed with correct, not modified name
          this.key += 1;
        })
        .finally(() => {
          this.modifiedGroup = null;
        });
    }
  }

  discard() {
    if (this.isPlaceholderGroup(this.modifiedGroup!)) {
      this.removeGroupPlaceholder();
    } else {
      // force component rerender if the app user wants to discard changes
      // so that the user is displayed with correct, not modified name
      this.key += 1;
    }
    this.modifiedGroup = null;
    this.showNameTooltip = false;
  }

  addGroup() {
    this.setSelectedGroup(null);
    this.addGroupPlaceholder();
    this.modifiedGroup = JSON.parse(JSON.stringify(this.groups.find((g) => !g.id)));
    focusOnNewItemNameInput();
  }

  mounted() {
    // select the frist group by default
    if (!this.selectedGroup && this.groups.length) {
      const groups = this.groups.filter((item) => item.name !== 'HISTORICALPARAMETERSMODE');
      const group = groups.sort((a, b) => sort(a.name, b.name))[0];
      this.setSelectedGroup(group);
    }
  }

  destroyed() {
    this.setSelectedGroup(null);
    this.removeGroupPlaceholder();
  }

  get groupNamesArray() {
    const groupNames: string[] = [];
    this.groups.forEach((g: Models.Auth.Groups.GroupsModel) => {
      groupNames.push(g.name);
    });
    return groupNames.filter((g) => g !== this.itemName);
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'users');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'users');
    return getPermissions.delete;
  }
}
