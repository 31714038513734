function focusOnNewItemNameInput() {
  setTimeout(() => {
    const nameInput: HTMLInputElement | null = document.querySelector('[placeholder="Nazwa"]:not([disabled="disabled"]');
    if (nameInput) {
      nameInput.focus();
    }
  }, 0);
}

export {
  focusOnNewItemNameInput
};
